import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'jquery'
import 'popper.js'
import 'bootstrap'
import './assets/useful.css'
import 'animate.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { registerScrollSpy } from 'vue3-scroll-spy'

const app = createApp(App).use(store).use(router)
registerScrollSpy(app)
AOS.init()
app.mount('#app')

global.jQuery = require('jquery')
var $ = global.jQuery
window.$ = $

$(document).ready(function () {
  $('[data-toggle="tooltip"]').tooltip()
})

// document.getElementById('#next').addEventListener('click', next)
