<template>
  <div id="nav">
    <router-link to="/">Home</router-link>
  </div>
  <router-view v-slot="{ Component, route }">
  <transition :enter-active-class="route.meta.enterClass"
    :lave-active-class="route.meta.leaveClass">
    <component :is="Component"/>
  </transition>
</router-view>

</template>

<style>
#app {
  font-family: Comfortaa;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  display: none;
}

</style>
