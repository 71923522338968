<template>
  <div>
    <link rel="icon" src="/src/assets/isotipo.png">
    <div class="d-none d-xl-block">
      <div id="buttonid">
      <router-link id="buttontoexperimental" to="/experimental">
      </router-link>
      </div>
    </div>
    <header class="fixed-top">
      <nav class="navbar navbar-expand-md navbar-dark bg-dark" id="navbar-hamburger">
        <div class="container-md">
          <a class="navbar-brand" href="#">
            <img src="../assets/isotipo.png">
          </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarDropdown" aria-controls="navbarDropdown" aria-expanded="false" aria-label="Toggle navigation" data-bs-auto-close="true">
            <img src="../assets/img/menu_hamburguer.png" alt="">
          </button>
            <div class="collapse navbar-collapse text-uppercase justify-content-end" id="navbarDropdown" >
              <ul class="navbar-nav" v-scroll-spy-active v-scroll-spy-link>
                <li class="nav-item">
                  <a class="nav-link" href="#projects">Projects</a>
                  <div class="dropdown-divider d-sm-block d-md-none"></div>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#formations">Formation</a>
                  <div class="dropdown-divider d-sm-block d-md-none"></div>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#inTheProject">In the project</a>
                  <div class="dropdown-divider d-sm-block d-md-none"></div>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#aboutMe">About Me</a>
                  <div class="dropdown-divider d-sm-block d-md-none"></div>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#contact">Contact</a>
                </li>
              </ul>
            </div>
        </div>
      </nav>
      <div id="scroll-indicator-large-device" class="d-none d-xl-block">
        <div class="progress-container">
          <div class="progress-bar" id="myBar"></div>
        </div>
      </div>
      <div id="scroll-indicator-small-device" class="d-lg-block d-xl-none">
        <div class="progress-container">
          <div class="progress-bar" id="myBar2"></div>
        </div>
      </div>
    </header>
    <div class="masthead">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-auto">
            <div class="masthead-intro text-uppercase">
              <h1>Hi, I'm <br> Xixi Chen, <br> know me</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-scroll-spy="{allowNoActive: true}">
    <section id="projects">
      <div class="container">
        <div class="row">
          <div class="col">
            <h1 class="section-heading text-uppercase" data-aos="fade-right">Projects</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-7 align-self-center d-none d-lg-block" data-aos="flip-right" data-aos-offset="500"
          data-aos-duration="250">
              <div class="project">
                <img src="../assets/img/laniakea.jpg">
                <div class="overlay justify-content-center">
                  <div class="overlay-content">
                    <h3 class="project-title text-uppercase">Laniakea</h3>
                    <h3 class="project-explanation">Project multimedia</h3>
                    <router-link class="view-more" to="/projects/laniakea">
                      <button class="text-uppercase btn-lg ">
                        View more
                      </button>
                    </router-link>
                  </div>
                </div>
              </div>
          </div>
          <div class="col-lg-5 projects-rights d-none d-lg-block">
            <div class="project project-right" data-aos="flip-left">
              <img src="../assets/img/fmcoo.jpg">
              <div class="overlay justify-content-center">
                <div class="overlay-content">
                  <h3 class="project-title text-uppercase">FMCOO</h3>
                  <h3 class="project-explanation">Imaginary Branding</h3>
                  <router-link class="view-more" to="/projects/fmcoo">
                      <button class="text-uppercase btn-lg">
                      View more
                      </button>
                    </router-link>
                </div>
              </div>
            </div>
            <div class="project project-right" data-aos="flip-left">
              <img src="../assets/img/reconstruccio.jpg">
              <div class="overlay justify-content-center">
                <div class="overlay-content">
                  <h3 class="project-title text-uppercase">Reconstruction</h3>
                  <h3 class="project-explanation">Board Game</h3>
                  <router-link class="view-more" to="/projects/reconstruction">
                    <button class="text-uppercase btn-lg">
                      View more
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="project">
              <img src="../assets/img/heritaje.jpg" data-aos="flip-left">
              <div class="overlay justify-content-center">
                <div class="overlay-content">
                  <h3 class="project-title text-uppercase">Heritage edible</h3>
                  <h3 class="project-explanation">Editorial design</h3>
                  <router-link class="view-more" to="/projects/heritageedible">
                    <button class="text-uppercase btn-lg">
                      View more
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <!-- Responsive projects -->
          <div class="col-md-6 d-lg-none">
            <div class="project-medium-content" data-aos="flip-left">
              <div class="project-img project-img-laniakea">
              </div>
              <div class="project-text">
                <h3 class="project-title text-uppercase">Laniakea</h3>
                <p class="project-explanation">Project multimedia</p>
                <router-link class="view-more" to="/projects/laniakea">
                  <button class="text-uppercase btn-lg">
                    View more
                  </button>
                </router-link>
              </div>
            </div>
            <div class="project-medium-content" data-aos="flip-left">
                <div class="project-img project-img-fmcoo">
                </div>
                <div class="project-text">
                  <h3 class="project-title text-uppercase">FMCOO</h3>
                  <p class="project-explanation">Imaginary Branding</p>
                  <router-link class="view-more" to="/projects/fmcoo">
                    <button class="text-uppercase btn-lg">
                      View more
                    </button>
                  </router-link>
                </div>
            </div>
          </div>
          <div class="col-md-6 d-lg-none">
              <div class="project-medium-content" data-aos="flip-left">
                <div class="project-img project-img-reconstruction">
                </div>
                <div class="project-text">
                  <h3 class="project-title text-uppercase">Reconstruction</h3>
                  <p class="project-explanation">Board Game</p>
                  <router-link class="view-more" to="/projects/reconstruction">
                    <button class="text-uppercase btn-lg">
                      View more
                    </button>
                  </router-link>
                </div>
              </div>
            <div class="project-medium-content" data-aos="flip-left">
                <div class="project-img project-img-heritage">
                </div>
                <div class="project-text">
                  <h3 class="project-title text-uppercase">Hetitage Edible</h3>
                  <p class="project-explanation">Editorial design</p>
                  <router-link class="view-more" to="/projects/heritageedible">
                    <button class="text-uppercase btn-lg">
                      View more
                    </button>
                  </router-link>
                </div>
              </div>
          </div>
        </div>
      </div>
    </section>

    <section id="formations">
      <div class="container">
        <div class="row">
          <div class="col justify-content-center">
            <h1 class="section-heading text-uppercase" data-aos="slide-up" data-aos-delay="200">Formation</h1>
          </div>
        </div>
         <div class="row">
          <div class="col">
           <ul class="timeline">
            <li class="timeline-inverted">
              <div class="timeline-image" data-aos="zoom-in"  data-aos-delay="100">
                <img class="glyphicon glyphicon-credit-card" src="../assets/img/internship.png">
              </div>
              <div class="timeline-panel">
                <div class="timeline-heading text-uppercase"  data-aos="zoom-in" data-aos-delay="200">
                  <h3 class="timeline-title">Internship in <a href="https://cookiebox.es/" target="_blank">Cookie Box S.L.</a></h3>
                </div>
                <div class="timeline-body" data-aos="zoom-in" data-aos-delay="300">
                  <p>User experience<br>New visions</p>
                </div>
              </div>
            </li>
            <li>
              <div class="timeline-image danger" data-aos="zoom-in" data-aos-delay="100">
                <img class="glyphicon glyphicon-credit-card" src="../assets/img/eram.png">
              </div>
              <div class="timeline-panel">
                <div class="timeline-heading" data-aos="zoom-in" data-aos-delay="200">
                  <h3 class="timeline-title text-uppercase">Audiovisual and Multimedia Degree, <a href="https://www.eram.cat/es" target="_blank">eram (Udg)</a></h3>
                </div>
                <div class="timeline-body" data-aos="zoom-in" data-aos-delay="300">
                  <p>More than interest<br>Another way<br>Something will be fixed</p>
                </div>
              </div>
            </li>
            <li class="timeline-inverted">
              <div class="timeline-image" data-aos="zoom-in" data-aos-delay="100"><img class="glyphicon glyphicon-credit-card" src="../assets/img/highschool.png"></div>
              <div class="timeline-panel">
                <div class="timeline-heading text-uppercase" data-aos="zoom-in" data-aos-delay="200">
                  <h3 class="timeline-title">Performing arts in High school</h3>
                </div>
                <div class="timeline-body" data-aos="zoom-in" data-aos-delay="300">
                  <p>New world<br>More possibilities</p>
                </div>
              </div>
            </li>
            <li>
              <div class="timeline-image danger" data-aos="zoom-in" data-aos-delay="100">
                <img class="glyphicon glyphicon-credit-card" src="../assets/img/links.png"></div>
              <div class="timeline-panel">
                <div class="timeline-heading" data-aos="zoom-in" data-aos-delay="200">
                  <h3 class="timeline-title text-uppercase">Studied in China and Spain</h3>
                </div>
                <div class="timeline-body" data-aos="zoom-in" data-aos-delay="300">
                  <p>2 Different cultures<br>3 Battles of Values<br>1 Way of living</p>
                </div>
              </div>
            </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section id="inTheProject">
      <div class="container">
         <div class="row">
          <div class="col justify-content-start" data-aos="flip-up">
            <h1 class="section-heading text-uppercase">In the project...</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6">
            <div class="rule" data-aos="flip-down">
              <p class="ruleNumber">1</p>
              <div class="rule-panel">
                <div class="rule-heading text-uppercase">
                  <h3>Persona</h3>
                </div>
                <p class="rule-content">I think that people are one of the most decisive factors when developing a project. In addition to taking into account the skilss of each one, I also focus on the personalities that they have. If a person cannot adapt or coordinate with the others, it is difficult to achieve an optimal result.</p>
              </div>
            </div>
            <div class="rule" data-aos="flip-down">
              <p class="ruleNumber">2</p>
              <div class="rule-panel">
                <div class="rule-heading text-uppercase">
                  <h3>Mode</h3>
                </div>
                <p class="rule-content">Normally I follow a fairly fluid working mode, I don't mind trying new working modes. I am usually a person with few words, but I am always attentive to what is happening and very focused on my responsibilities.</p>
              </div>
            </div>
          </div>
          <div class="col-xl-6 align-self-center">
            <div class="rule" data-aos="flip-down">
              <p class="ruleNumber">3</p>
              <div class="rule-panel">
                <div class="rule-heading text-uppercase">
                  <h3>Project</h3>
                </div>
                <p class="rule-content">The theme of the project is also quite a deciding factor. Although if a not so attractive project offers me a different position than the usual one, there is a good chance of accepting it. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="aboutMe">
      <div id="defineMe">
        <div class="container">
          <div class="row">
            <div class="col justify-content-start">
              <h1 class="section-heading text-uppercase" data-aos="fade-down-right">About me</h1>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="activities">
                <div class="hexagon" data-aos="fade-up">
                  <div class="shape">
                    <img src="../assets/img/hexagon/cinema.jpg">
                  </div>
                  <div class="hexagon-overlay">
                    <h3>Cinema</h3>
                  </div>
                </div>
                <div class="hexagon" data-aos="fade-down">
                  <div class="shape">
                    <img src="../assets/img/hexagon/paper.jpg">
                  </div>
                  <div class="hexagon-overlay">
                    <h3>Online novels</h3>
                  </div>
                </div>
                <div class="hexagon">
                  <div class="shape"  data-aos="fade-up">
                    <img src="../assets/img/hexagon/friends.jpg">
                  </div>
                  <div class="hexagon-overlay">
                    <h3>Being with friends</h3>
                  </div>
                </div>
                <div class="hexagon" data-aos="fade-down">
                  <div class="shape">
                    <img src="../assets/img/hexagon/anime.jpg">
                  </div>
                  <div class="hexagon-overlay">
                    <h3>Anime</h3>
                  </div>
                </div>
                <div class="hexagon" data-aos="fade-down">
                  <div class="shape">
                    <img src="../assets/img/hexagon/sportsshoes.jpg">
                  </div>
                  <div class="hexagon-overlay">
                    <h3>Sport</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="whatDoIwork">
        <div class="container">
          <div class="row">
            <div class="col-auto">
              <h2 class="minisection-heading  text-capitalize" data-aos="fade-down-right">What do i work?</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 align-self-center justify-content-center" data-aos="flip-left">
              <img src="../assets/img/whatdoiwork/editvideo.png">
              <h3 class="work">Edit</h3>
            </div>
            <div class="col-lg-4 align-self-center justify-content-center border-line" data-aos="flip-left">
              <img src="../assets/img/whatdoiwork/program.png">
              <h3 class="work">Program</h3>
            </div>
            <div class="col-lg-4 justify-content-center align-self-center" data-aos="flip-left">
              <img src="../assets/img/whatdoiwork/design.png">
              <h3 class="work">Design</h3>
            </div>
          </div>
        </div>
      </div>
      <div id="someHabilities">
        <div class="container">
          <div class="row">
            <div class="col-auto">
              <div class="minisection" data-aos="fade-down-right">
                <h2 class="minisection-heading text-capitalize">
                Some skills
                </h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6  align-self-center">
              <div class="language">
                <div class="language-panel">
                  <h3 data-aos="zoom-in-down" data-aos-delay="300">Chinese</h3>
                  <div class="progress" data-aos="zoom-in-up">
                    <div class="progress-bar" role="progressbar" style="width: 85%" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100">
                    </div>
                  </div>
                </div>
                <div class="language-panel">
                  <h3 data-aos="zoom-in-down" data-aos-delay="300">English</h3>
                  <div class="progress" data-aos="zoom-in-up">
                      <div class="progress-bar" role="progressbar" style="width: 60%" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">
                      </div>
                  </div>
                </div>
                <div class="language-panel">
                  <h3 data-aos="zoom-in-down" data-aos-delay="300">Spanish</h3>
                  <div class="progress" data-aos="zoom-in-up">
                    <div class="progress-bar" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6 align-self-center d-none d-lg-block">
              <div class="charthexagon">
              <div id="chart">
                <vue3-chart-js v-bind="{ ...barChart }" />
              </div>
              </div>
            </div>
            <div class="col-xl-6 align-self-center d-block d-lg-none">
              <div class="charthexagon">
              <div id="chart">
                <vue3-chart-js v-bind="{ ...barChart2 }" />
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="contact">
      <div class="experimental-message d-xl-none">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <h3>Hi, we are here, see us in the laptops!</h3>
            </div>
          </div>
      </div>
    </div>
      <div class="container-fluid">
        <div class="row">
          <div class="contactme col-lg-6 justify-content-center">
            <div class="panel">
              <h3 data-aos="fade-up"> If you have any interesting</h3>
              <button class="text-uppercase btn btn-lg" data-aos="flip-up"><a href="mailto:xixichenyy@gmail.com">Contact-me</a></button>
              <h3 data-aos="fade-up">xixichenyy@gmail.com</h3>
            </div>
          </div>
          <div class="download col-lg-6 justify-content-center">
            <div class="panel">
              <h3 class="heading" data-aos="fade-up">That's my curriculum</h3>
              <button class="text-uppercase btn btn-lg" data-aos="flip-up">
                 <a href="https://drive.google.com/file/d/1R6_q2LJHWqLRSVDc17iq-2BS5K0LeY3Q/view?usp=sharing" target="_blank"> download</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
    <footer>
      <div class="container">
        <p class="text-start">
          © 2021 Copyright: Xixi Chen
        </p>
      </div>
    </footer>
    </div>
</template>
<script>
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'

export default {
  name: 'Home, ProgressIndicator',
  components: {
    Vue3ChartJs
  },
  setup () {
    const barChart = {
      type: 'radar',
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        layout: {
          padding: {
            bottom: 25 // set that fits the best
          }
        },
        scales: {
          r: {
            ticks: {
              count: 5,
              display: false,
              color: 'white'
            },
            angleLines: {
              display: false
            },
            borderColor: '#ffffff',
            pointLabels: {
              color: '#ffffff',
              font: {
                size: 28,
                family: 'Comfortaa'
              }
            },
            grid: {
              color: 'white',
              lineWidth: 3
            },
            tooltips: {
              intersect: false,
              enabled: false
            },
            min: 0,
            max: 50
          }
        },
        responsive: true,
        maintainAspectRatio: false
      },
      data: {
        labels: [
          'Responsable',
          'Team work',
          'Individual work',
          'Puntual',
          'Patient'
        ],
        datasets: [
          {
            label: 'Skip first dataset',
            title: {
              display: false
            },
            data: [
              50,
              40,
              45,
              50,
              45
            ],
            pointRadius: 0,
            borderColor: 'rgba(226, 187, 146, 0.75)',
            backgroundColor: 'rgba(226, 187, 146, 0.75)'
          }
        ]
      }
    }
    const barChart2 = {
      type: 'radar',
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        layout: {
          padding: {
            bottom: 25 // set that fits the best
          }
        },
        scales: {
          r: {
            ticks: {
              count: 5,
              display: false,
              color: 'white'
            },
            angleLines: {
              display: false
            },
            borderColor: '#ffffff',
            pointLabels: {
              color: '#ffffff',
              font: {
                size: 20,
                family: 'Comfortaa'
              }
            },
            grid: {
              color: 'white',
              lineWidth: 3
            },
            tooltips: {
              intersect: false,
              enabled: false
            },
            min: 0,
            max: 50
          }
        },
        responsive: true,
        maintainAspectRatio: false
      },
      data: {
        labels: [
          'Responsable',
          'Team work',
          'Individual work',
          'Puntual',
          'Patient'
        ],
        datasets: [
          {
            label: 'Skip first dataset',
            title: {
              display: false
            },
            data: [
              50,
              40,
              45,
              50,
              45
            ],
            pointRadius: 0,
            borderColor: 'rgba(226, 187, 146, 0.75)',
            backgroundColor: 'rgba(226, 187, 146, 0.75)'
          }
        ]
      }
    }

    return {
      barChart,
      barChart2
    }
  }
}

window.onscroll = function () {
  myFunction()
}

function myFunction () {
  var winScroll = document.body.scrollTop || document.documentElement.scrollTop

  var height = document.documentElement.scrollHeight - document.documentElement.clientHeight
  var scrolled = (winScroll / height) * 100
  document.getElementById('myBar').style.width = scrolled + '%'
  document.getElementById('myBar2').style.width = scrolled + '%'
}
</script>

<style>
.skills h3{
  margin-top:20px;
}

.skills h3:nth-child(1){
  text-align: left;
}
.skills h3:nth-child(5){
  text-align: left;
}
.skills h3:nth-child(3){
  text-align: right;
}

#header-mobile .navbar .navbar-toggler img{
  width: 30px;
  height: auto;
}
.scrollspy-example{
  position: relative;
}

.navbar-brand img{
  width: 35px;
  height: auto;
}

#buttontoexperimental{
  height: 100vh;
  width: 50px;
  position: fixed;
  z-index: 1031;
  right: 0;
  border: none;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
  background-repeat: repeat;
  background-size: 100px 100px;
  background-image: url("../assets/img/experimental/motivo-experimental.svg");
  border-left: 5px solid var(--brown);
}

#buttontoexperimental:hover{
  background-size: 150px 150px;
  width: 110px;
}

#buttontoexperimental:after {
  content: '‹‹';
  position: absolute;
  opacity: 1;
  transition: 0.3s;
  left:10px;
  color: var(--brown);
  height: 100vh;
  display: flex;
  align-items: center;
  font-size: 50px;
  font-family: Comfortaa;
  width: 100%;
}

#buttontoexperimental:hover:after {
  opacity: 1;
  left:-50px;
}

.experimental-message{
  background-image: url("../assets/img/mobilemessage.jpg");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: cover;
  backdrop-filter: opacity(20%);
  color:var(--white);
}

.experimental-message h3{
  margin: auto;
  padding-top:120px;
  padding-bottom: 120px;
  color: var(--darkblue);
}

.experimental-message .container-fluid{
  background-color: rgba(221, 237, 239, 0.4);
}

</style>
