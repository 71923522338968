import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      enterClass: 'animate__animated animate__slideInLeft',
      leaveClass: 'animate__animated animate__slideOutRight'
    }
  },
  {
    path: '/experimental',
    name: 'experimental',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/experimental'),
    meta: {
      enterClass: 'animate__animated animate__slideInRight',
      leaveClass: 'animate__animated animate__slideOutLeft'
    }
  },
  {
    path: '/projects/laniakea',
    name: 'laniakea',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/laniakea.vue'),
    meta: {
      enterClass: 'animate__animated animate__fadeInDown',
      leaveClass: 'animate__animated animate__fadeOutDown'
    }
  },
  {
    path: '/projects/fmcoo',
    name: 'fmcoo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/fmcoo.vue'),
    meta: {
      enterClass: 'animate__animated animate__fadeInDown',
      leaveClass: 'animate__animated animate__fadeOutDown'
    }
  },
  {
    path: '/projects/reconstruction',
    name: 'reconstruction',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/reconstruction.vue'),
    meta: {
      enterClass: 'animate__animated animate__fadeInDown',
      leaveClass: 'animate__animated animate__fadeOutDown'
    }
  },
  {
    path: '/projects/heritageedible',
    name: 'heritageedible',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/heritageedible.vue'),
    meta: {
      enterClass: 'animate__animated animate__fadeInDown',
      leaveClass: 'animate__animated animate__fadeOutDown'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
